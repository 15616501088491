<template>
  <div class="zhyj">
    <div class="container">
      <div class="banner">
        <div class="auto-container">
          <h1>智慧硬件对接</h1>
          <p><a href="/">首页</a>
            <Icon
              class="arrow"
              type="ios-arrow-forward"
            />我们的优势
            <Icon
              class="arrow"
              type="ios-arrow-forward"
            />智慧硬件对接
          </p>
        </div>
      </div>
      <div class="auto-container">
        <section class="rules-section">
          <div class="auto-container">
            <Row>
              <Col :xl="10" :lg="14">
              <div class="inner-column">
                <div class="image">
                  <a href="#"><img
                      src="../../assets/resource/image-2.png"
                      alt=""
                    /></a>
                </div>
              </div>
              </Col>

              <Col :xl="14" :lg="10">
              <div class="inner-column">
                <div class="sec-title">
                  <div class="title-icon">
                    <span class="icon"><img
                        src="../../assets/icons/separater.png"
                        alt=""
                      /></span>
                  </div>
                  <h2>智慧硬件对接</h2>
                </div>
                <div class="text">
                  <p>精湛的智慧硬件对接能力，使得我们可以轻松对接主流厂商设备数据</p>
                  <!-- <p>平台将一系列业务活动抽象为不同类型的“流程节点”，通过流程编辑器进行连接，可实现业务流程触发、流转、审批、消息推送等操作，
                    免去了代码编写工作，让使用者能够完全专注于校园业务场景，为学校提供智慧灵活的办公OA搭建工具。</p>-->
                </div> 
              </div>
              </Col>
            </Row>
          </div>
        </section>

        <Row style="padding-bottom:80px">
          <Col
            :xl="8" :lg="8" :md="12" :sm="24"
            v-for="(item,index) in feature"
            :key="index"
          >
          <div class="feature-block-two">
            <div class="inner-box ">
              <div class="icon-box">
                <img :src="item.numberPic" alt="" />
              </div>
              <div class="text">{{item.text}}</div>
            </div>
          </div>
          </Col>
        </Row>

      </div>
    </div>
  </div>
</template>
<script>
import zhyj from "./zhyj.js";
export default zhyj;
</script>

<style lang="less" scoped>
@import "./zhyj.less";
</style>