export default {
    data() {
        return {
            feature: [{
                title: '特点1',
                numberPic: require("../../assets/icons/num1.png"),
                text: '系统支持通过图形化界面拖拽组件，自由搭设需要的内容及工作流程，同时可以根据不同工作流程可视化设置不同的权限，实现业务模块的快速开发。'
            }, {
                title: '特点2',
                numberPic: require("../../assets/icons/num2.png"),
                text: '系统支持教师、学生、场馆、年级班级、部门、教学周、学科等校园专用场景组件，同时包含常用文本、单多选、时间选择器、图片、附件等组件，各组件可以动态设置各类参数。'
            }, {
                title: '特点3',
                numberPic: require("../../assets/icons/num3.png"),
                text: '系统自定义工作流可以自定义流程分支，任意环节可设置审核人、办理人、抄送人及子分支，审核权限支持或签或者会签，人员权限设置支持学校组织架构勾选以及班主任、任课教师等组权限'
            }, {
                title: '特点4',
                numberPic: require("../../assets/icons/num4.png"),
                text: '系统生成的列表支持标题及内容根据填写字段自定义显示，可以自定义生成表单的tab菜单及功能，支持提交后可编辑功能，支持设置日提交数量及提交总数等频率设置，支持表单广告屏显示设置'
            }, {
                title: '特点5',
                numberPic: require("../../assets/icons/num5.png"),
                text: '系统具备多终端适应能力，可实现多终端展示，如PC端、广告屏、移动APP、钉钉、微信公众号及企业微信等。'
            }, {
                title: '特点6',
                numberPic: require("../../assets/icons/num6.png"),
                text: '各审批、交办及抄送业务流程均可自动生成消息推送功能。'
            }],
        }
    },
    created() {

    },
}